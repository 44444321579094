import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BlogArticle from "./BlogArticle";

const NewsItemPage = ({
  data,
}: {
  data: { markdownRemark: { [key: string]: any } };
}) => {
  const article = data.markdownRemark;
  return (
    <Layout
      seo={{ title: data.markdownRemark.frontmatter.title }}
      backgroundColorUnderneath="white"
    >
      <BlogArticle article={article} />
    </Layout>
  );
};

export const query = graphql`
  query BlogArticle($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "D MMMM YYYY")
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 390)
          }
        }
      }
    }
  }
`;

export default NewsItemPage;
