import { Link } from "gatsby";
import React from "react";
import * as yup from "yup";

import {
  detailSmall,
  heading200,
  paragraphTextSmall,
} from "../../../stitches/combinedStyles";
import { styled } from "../../../stitches/stitches.config";
import Button from "../../shared/Button/Button";

const title = `Got a question for our materials scientists?`;
const byline = `Do you have any questions about this page or any of the technologies we develop at Plastometrex? Our friendly materials scientists are here to help.`;
const buttonText = `Ask a question`;

const Question = () => {
  return (
    <Container>
      <ContentContainer>
        <Title>{title}</Title>
        <Byline>{byline}</Byline>
        <Link to="/ask-a-question">
          <Button size="large" tier="primary" arrow inverted>
            {buttonText}
          </Button>
        </Link>
      </ContentContainer>
    </Container>
  );
};

export default Question;

const Container = styled("div", {
  backgroundColor: "$blue100",
  width: "100%",
  margin: "auto",
  maxWidth: 1140,
  marginBottom: 0,
  "@md": {
    borderRadius: 16,
    width: "90%",
    marginBottom: 100,
  },
});

const ContentContainer = styled("div", {
  padding: 34,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
});

const Title = styled("h4", {
  ...heading200,
  color: "$white",
  margin: 0,
  marginBottom: 12,
});

const Byline = styled("p", {
  ...paragraphTextSmall,
  color: "$white",
  margin: 0,
  opacity: 0.7,
  marginBottom: 32,
  maxWidth: 800,
  "@md": {
    marginBottom: 32,
  },
});

const FormContainer = styled("form", {
  display: "flex",
  flexDirection: "column",
});

const InputWrapper = styled("div", {
  position: "relative",
  width: "100%",
  margin: "auto",
  marginBottom: 12,
  "@md": {
    maxWidth: 430,
  },
});

const Input = styled("input", {
  ...paragraphTextSmall,
  color: "$black55",
  padding: "14px 20px 14px 43px",
  margin: 0,
  borderRadius: 8,
  border: 0,
  width: "100%",
  boxSizing: "border-box",
});

const MobileButton = styled(Button, {
  display: "block",
  "@md": {
    display: "none",
  },
});
const DesktopButton = styled(Button, {
  position: "absolute",
  right: 7,
  top: 8,
  display: "none",
  "@md": {
    display: "block",
  },
});

const InputError = styled("span", {});

const Disclaimer = styled("p", {
  ...detailSmall,
  color: "$white",
  opacity: 0.7,
});
